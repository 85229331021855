import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/Logitech_Harmony/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Logitech Harmony and INSTAR MQTT",
  "path": "/Software/Home_Automation/Logitech_Harmony/",
  "dateChanged": "2020-03-10",
  "author": "Mike Polinowski",
  "excerpt": "Logitech Harmony is a universal remote control that can be programmed to communicate not only with your television, but also with your smarthome components. The following tutorials will show you how to install Fakeroku on your smarthome system to create a virtual interface the Logitech Harmony Hub can communicate with.",
  "social": "/images/Search/P_SearchThumb_Harmony.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Harmony_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Harmony"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Logitech Harmony and INSTAR MQTT' dateChanged='2020-03-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Logitech Harmony is a universal remote control that can be programmed to communicate not only with your television, but also with your smarthome components. The following tutorials will show you how to install Fakeroku on your smarthome system to create a virtual interface the Logitech Harmony Hub can communicate with.' image='/images/Search/P_SearchThumb_Harmony.png' twitter='/images/Search/P_SearchThumb_Harmony.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/Logitech_Harmony/' locationFR='/fr/Home_Automation/Logitech_Harmony/' crumbLabel="Harmony" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <h3 {...{
      "id": "logitech-harmony-and-instar-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#logitech-harmony-and-instar-mqtt",
        "aria-label": "logitech harmony and instar mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logitech Harmony and INSTAR MQTT`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#smarthome-integration"
        }}>{`Smarthome Integration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-the-virtual-device-to-your-harmony-hub"
        }}>{`Adding the Virtual Device to your Harmony Hub`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h2>
    <p>{`Logitech Harmony is a universal remote control that can be programmed to communicate not only with your television, but also with your smarthome components. The following tutorials will show you how to install Fakeroku on your smarthome system to create a virtual interface the Logitech Harmony Hub can communicate with.`}</p>
    <p>{`We will then continue connecting Fakeroku with the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`INSTAR MQTT Interface`}</a>{` of your Full HD Camera allowing you to take control of your camera with the Logitech remote.`}</p>
    <h2 {...{
      "id": "smarthome-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-integration",
        "aria-label": "smarthome integration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Integration`}</h2>
    <p>{`The Fakeroku extension can be installed on the following Smarthome systems:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Home_Automation/Logitech_Harmony/Node-RED/#install-fakeroku-on-node-red"
        }}>{`Node-RED`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Home_Automation/Logitech_Harmony/ioBroker/#install-the-fakeroku-adapter-in-iobroker"
        }}>{`ioBroker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Home_Automation/Logitech_Harmony/Home_Assistant/#install-fakeroku-on-home-assistant"
        }}>{`Home Assistant`}</a></li>
    </ul>
    <p>{`Please follow the instructions to install the software on your system and then return to continue adding the virtual device to your Logitech Harmony Hub.`}</p>
    <h2 {...{
      "id": "adding-the-virtual-device-to-your-harmony-hub",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-the-virtual-device-to-your-harmony-hub",
        "aria-label": "adding the virtual device to your harmony hub permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the Virtual Device to your Harmony Hub`}</h2>
    <p>{`To add the Fakeroku device to your Harmony Hub open the Harmony Smartphone app and find the configuration page for your hub. Select to add a new `}<strong parentName="p">{`Device`}</strong>{` and scan your local WiFi for available devices on your network:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec3573f3c13af3dd5bcb723e086d6209/4b190/Logitech_Harmony_INSTAR_MQTT_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd0gsD//xAAWEAADAAAAAAAAAAAAAAAAAAABECD/2gAIAQEAAQUCgL//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAACAwAAAAAAAAAAAAAAAAAAAREgQf/aAAgBAQABPyEUZV//2gAMAwEAAgADAAAAELAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBAAAAYDAAAAAAAAAAAAAAAAAAEQESGRMUFx/9oACAEBAAE/EKBjPDMwKTbqf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec3573f3c13af3dd5bcb723e086d6209/e4706/Logitech_Harmony_INSTAR_MQTT_01.avif 230w", "/en/static/ec3573f3c13af3dd5bcb723e086d6209/d1af7/Logitech_Harmony_INSTAR_MQTT_01.avif 460w", "/en/static/ec3573f3c13af3dd5bcb723e086d6209/c9875/Logitech_Harmony_INSTAR_MQTT_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec3573f3c13af3dd5bcb723e086d6209/a0b58/Logitech_Harmony_INSTAR_MQTT_01.webp 230w", "/en/static/ec3573f3c13af3dd5bcb723e086d6209/bc10c/Logitech_Harmony_INSTAR_MQTT_01.webp 460w", "/en/static/ec3573f3c13af3dd5bcb723e086d6209/d00b9/Logitech_Harmony_INSTAR_MQTT_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec3573f3c13af3dd5bcb723e086d6209/e83b4/Logitech_Harmony_INSTAR_MQTT_01.jpg 230w", "/en/static/ec3573f3c13af3dd5bcb723e086d6209/e41a8/Logitech_Harmony_INSTAR_MQTT_01.jpg 460w", "/en/static/ec3573f3c13af3dd5bcb723e086d6209/4b190/Logitech_Harmony_INSTAR_MQTT_01.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec3573f3c13af3dd5bcb723e086d6209/4b190/Logitech_Harmony_INSTAR_MQTT_01.jpg",
              "alt": "Logitech Harmony MQTT Control",
              "title": "Logitech Harmony MQTT Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Fakeroku virtual device you installed on your smarthome system should now show up as `}<strong parentName="p">{`Roku 3`}</strong>{` (you can rename the device in case you plan to add more than one Fakeroku instance):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4bb0e1fa79e4e2199103640757029738/4b190/Logitech_Harmony_INSTAR_MQTT_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABbzk+NKHtH//EABoQAAICAwAAAAAAAAAAAAAAAAACARIDEDP/2gAIAQEAAQUCLLBddZeh/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECAxESE//aAAgBAwEBPwGyeqyd2f/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AUf/xAAYEAADAQEAAAAAAAAAAAAAAAAAARAxMv/aAAgBAQAGPwLTUdKOf//EABoQAAICAwAAAAAAAAAAAAAAAAABEBExgfH/2gAIAQEAAT8h0LFNTOgPKhI//9oADAMBAAIAAwAAABDI7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAYf/aAAgBAwEBPxBCBZX/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QDY//xAAcEAEAAQQDAAAAAAAAAAAAAAABABAhMbFBYfH/2gAIAQEAAT8QFHB1aLHLgoTzVFp6JhP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4bb0e1fa79e4e2199103640757029738/e4706/Logitech_Harmony_INSTAR_MQTT_02.avif 230w", "/en/static/4bb0e1fa79e4e2199103640757029738/d1af7/Logitech_Harmony_INSTAR_MQTT_02.avif 460w", "/en/static/4bb0e1fa79e4e2199103640757029738/c9875/Logitech_Harmony_INSTAR_MQTT_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4bb0e1fa79e4e2199103640757029738/a0b58/Logitech_Harmony_INSTAR_MQTT_02.webp 230w", "/en/static/4bb0e1fa79e4e2199103640757029738/bc10c/Logitech_Harmony_INSTAR_MQTT_02.webp 460w", "/en/static/4bb0e1fa79e4e2199103640757029738/d00b9/Logitech_Harmony_INSTAR_MQTT_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4bb0e1fa79e4e2199103640757029738/e83b4/Logitech_Harmony_INSTAR_MQTT_02.jpg 230w", "/en/static/4bb0e1fa79e4e2199103640757029738/e41a8/Logitech_Harmony_INSTAR_MQTT_02.jpg 460w", "/en/static/4bb0e1fa79e4e2199103640757029738/4b190/Logitech_Harmony_INSTAR_MQTT_02.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4bb0e1fa79e4e2199103640757029738/4b190/Logitech_Harmony_INSTAR_MQTT_02.jpg",
              "alt": "Logitech Harmony MQTT Control",
              "title": "Logitech Harmony MQTT Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Continue by adding an `}<strong parentName="p">{`Activity`}</strong>{` for your new device:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/98cea89942f09d5021c44fcfdaeec12c/4b190/Logitech_Harmony_INSTAR_MQTT_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAHaRHPelF//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIREjH/2gAIAQEAAQUC0jcTSKRSI8//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIT/9oACAEDAQE/AdWKz//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAYEAADAQEAAAAAAAAAAAAAAAAAATEgMv/aAAgBAQAGPwLpFRcf/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAFhMUFRgZHx/9oACAEBAAE/IfeFgtiZRekCIEKrD//aAAwDAQACAAMAAAAQdP8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxDIY1Yf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QI//EAB0QAAICAQUAAAAAAAAAAAAAAAABESFRYYGRwfH/2gAIAQEAAT8QWa2ELgQE0srQVdR4Qm0Irwf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98cea89942f09d5021c44fcfdaeec12c/e4706/Logitech_Harmony_INSTAR_MQTT_03.avif 230w", "/en/static/98cea89942f09d5021c44fcfdaeec12c/d1af7/Logitech_Harmony_INSTAR_MQTT_03.avif 460w", "/en/static/98cea89942f09d5021c44fcfdaeec12c/c9875/Logitech_Harmony_INSTAR_MQTT_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/98cea89942f09d5021c44fcfdaeec12c/a0b58/Logitech_Harmony_INSTAR_MQTT_03.webp 230w", "/en/static/98cea89942f09d5021c44fcfdaeec12c/bc10c/Logitech_Harmony_INSTAR_MQTT_03.webp 460w", "/en/static/98cea89942f09d5021c44fcfdaeec12c/d00b9/Logitech_Harmony_INSTAR_MQTT_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98cea89942f09d5021c44fcfdaeec12c/e83b4/Logitech_Harmony_INSTAR_MQTT_03.jpg 230w", "/en/static/98cea89942f09d5021c44fcfdaeec12c/e41a8/Logitech_Harmony_INSTAR_MQTT_03.jpg 460w", "/en/static/98cea89942f09d5021c44fcfdaeec12c/4b190/Logitech_Harmony_INSTAR_MQTT_03.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/98cea89942f09d5021c44fcfdaeec12c/4b190/Logitech_Harmony_INSTAR_MQTT_03.jpg",
              "alt": "Logitech Harmony MQTT Control",
              "title": "Logitech Harmony MQTT Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Rename the activity and add an icon:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/abc20f75eb461a69c03b494cea9180cb/4b190/Logitech_Harmony_INSTAR_MQTT_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26FB/8QAFhABAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAEBAAEFAmc//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxABAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAQABPyFZUdn/2gAMAwEAAgADAAAAEIPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAFxABAQEBAAAAAAAAAAAAAAAAEQABQf/aAAgBAQABPxDiuMUzICX/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abc20f75eb461a69c03b494cea9180cb/e4706/Logitech_Harmony_INSTAR_MQTT_04.avif 230w", "/en/static/abc20f75eb461a69c03b494cea9180cb/d1af7/Logitech_Harmony_INSTAR_MQTT_04.avif 460w", "/en/static/abc20f75eb461a69c03b494cea9180cb/c9875/Logitech_Harmony_INSTAR_MQTT_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/abc20f75eb461a69c03b494cea9180cb/a0b58/Logitech_Harmony_INSTAR_MQTT_04.webp 230w", "/en/static/abc20f75eb461a69c03b494cea9180cb/bc10c/Logitech_Harmony_INSTAR_MQTT_04.webp 460w", "/en/static/abc20f75eb461a69c03b494cea9180cb/d00b9/Logitech_Harmony_INSTAR_MQTT_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abc20f75eb461a69c03b494cea9180cb/e83b4/Logitech_Harmony_INSTAR_MQTT_04.jpg 230w", "/en/static/abc20f75eb461a69c03b494cea9180cb/e41a8/Logitech_Harmony_INSTAR_MQTT_04.jpg 460w", "/en/static/abc20f75eb461a69c03b494cea9180cb/4b190/Logitech_Harmony_INSTAR_MQTT_04.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/abc20f75eb461a69c03b494cea9180cb/4b190/Logitech_Harmony_INSTAR_MQTT_04.jpg",
              "alt": "Logitech Harmony MQTT Control",
              "title": "Logitech Harmony MQTT Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Assign the activity to a device - in this screenshot I added 3 instances of Fakeroku from 3 different smarthome systems and named them accordingly. If you did not rename your device in a previous step, it will just show up as `}<strong parentName="p">{`Roku 3`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fff33aaa37a32f341c6474bc7f768342/4b190/Logitech_Harmony_INSTAR_MQTT_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3KFB/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhAAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAE/IRX/AP/aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAADAQEBAAAAAAAAAAAAAAAAARExIXH/2gAIAQEAAT8Qo2q56LmGn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fff33aaa37a32f341c6474bc7f768342/e4706/Logitech_Harmony_INSTAR_MQTT_05.avif 230w", "/en/static/fff33aaa37a32f341c6474bc7f768342/d1af7/Logitech_Harmony_INSTAR_MQTT_05.avif 460w", "/en/static/fff33aaa37a32f341c6474bc7f768342/c9875/Logitech_Harmony_INSTAR_MQTT_05.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fff33aaa37a32f341c6474bc7f768342/a0b58/Logitech_Harmony_INSTAR_MQTT_05.webp 230w", "/en/static/fff33aaa37a32f341c6474bc7f768342/bc10c/Logitech_Harmony_INSTAR_MQTT_05.webp 460w", "/en/static/fff33aaa37a32f341c6474bc7f768342/d00b9/Logitech_Harmony_INSTAR_MQTT_05.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fff33aaa37a32f341c6474bc7f768342/e83b4/Logitech_Harmony_INSTAR_MQTT_05.jpg 230w", "/en/static/fff33aaa37a32f341c6474bc7f768342/e41a8/Logitech_Harmony_INSTAR_MQTT_05.jpg 460w", "/en/static/fff33aaa37a32f341c6474bc7f768342/4b190/Logitech_Harmony_INSTAR_MQTT_05.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fff33aaa37a32f341c6474bc7f768342/4b190/Logitech_Harmony_INSTAR_MQTT_05.jpg",
              "alt": "Logitech Harmony MQTT Control",
              "title": "Logitech Harmony MQTT Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now switch back to the smarthome specific part of this tutorial to connect the button presses on your Harmony Remote to the INSTAR MQTT Server on your camera:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Home_Automation/Logitech_Harmony/Node-RED/#instar-mqtt-setup"
        }}>{`Node-RED`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Home_Automation/Logitech_Harmony/ioBroker/#instar-mqtt-setup"
        }}>{`ioBroker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Home_Automation/Logitech_Harmony/Home_Assistant/#instar-mqtt-setup"
        }}>{`Home Assistant`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      